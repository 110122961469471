'use client';

import Link from 'next/link';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconTypes } from './Icons';
import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';

type Props = {
  href?: string;
  label?: string;
  onClick?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  target?: string;
  title?: string;
  form?: string;
  className?: string;
  size?: 'tiny' | 'normal' | 'medium' | 'large';
  icon?: IconTypes;
  iconEffect?: 'spin' | null;
  rightIcon?: IconTypes;
  leftIcon?: IconTypes;
  disabled?: boolean;
  variant?: 'default' | 'primary' | 'transparent';
  type?: 'button' | 'reset' | 'submit';
};

export const Button: React.FC<Props> = ({
  className,
  label,
  onClick,
  onMouseDown,
  onMouseUp,
  icon,
  leftIcon,
  rightIcon,
  iconEffect,
  href,
  target,
  title,
  form,
  disabled,
  type = 'button',
  variant = 'default',
  size = 'normal',
}) => {
  const { setContextMenu } = useFloatingUIStore();
  const paddingXKlass = !icon ? 'px-5' : size === 'large' ? 'w-16' : size === 'medium' ? 'w-12' : size === 'tiny' ? 'w-8' : 'w-10';
  const heightKlass = size === 'large' ? 'h-16' : size === 'medium' ? 'h-12' : size === 'tiny' ? 'h-8' : 'h-10';
  const bgKlass = variant === 'primary' ? 'bg-brand-primary text-white' : variant === 'default' ? 'bg-blue-100/10 text-blue-10' : 'bg-transparent';
  const bgHoverKlass = variant === 'primary' ? 'hover:opacity-70' : variant === 'default' ? 'hover:bg-blue-100/20' : 'hover:bg-blue-100/5';
  const iconEffectKlass = iconEffect ? (iconEffect === 'spin' ? 'animate-spin' : '') : '';
  const iconSizeKlass = size === 'tiny' ? 'size-5' : 'size-6';
  const iconKlass = twMerge(iconSizeKlass, iconEffectKlass);

  const klass = twMerge(
    'flex items-center justify-center gap-2 rounded-full transition-all duration-200 text-nowrap',
    disabled && 'opacity-40 cursor-not-allowed',
    heightKlass,
    paddingXKlass,
    bgKlass,
    !disabled && bgHoverKlass,
    className
  );

  if (href) {
    return (
      <>
        {disabled ? (
          <p className={klass}>
            {leftIcon && <Icon type={leftIcon} />}
            {label && <span>{label}</span>}
            {icon && <Icon type={icon} />}
            {rightIcon && <Icon type={rightIcon} />}
          </p>
        ) : (
          <Link className={klass} href={href} target={target} title={title} onClick={() => setContextMenu(null)}>
            {leftIcon && <Icon type={leftIcon} />}
            {label && <span>{label}</span>}
            {icon && <Icon type={icon} />}
            {rightIcon && <Icon type={rightIcon} />}
          </Link>
        )}
      </>
    );
  }

  return (
    <button
      className={klass}
      title={title}
      onClick={disabled ? undefined : onClick}
      onMouseDown={disabled ? undefined : onMouseDown}
      onMouseUp={disabled ? undefined : onMouseUp}
      type={type}
      disabled={disabled}
      form={form}
    >
      {leftIcon && <Icon className={iconKlass} type={leftIcon} />}
      {label && <span>{label}</span>}
      {icon && <Icon className={iconKlass} type={icon} />}
      {rightIcon && <Icon className={iconKlass} type={rightIcon} />}
    </button>
  );
};
