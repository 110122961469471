import { AlertType } from '@/packages/ui/Alert';
import { ContextMenuContentProps } from '@/packages/ui/ContextMenu';
import { DialogType } from '@/packages/ui/Dialog';
import { NotificationType } from '@/packages/ui/Notification';
import { TooltipContentProps } from '@/packages/ui/Tooltip';
import { AudioPlayerType } from '@/packages/ui/player/AudioPlayer';
import { uuidv4 } from '@/packages/utils/functions';
import { create } from 'zustand';

type FloatingUIStore = {
  audioPlayer?: AudioPlayerType | null;
  notifications: NotificationType[];
  tooltip?: TooltipContentProps | null;
  alert?: AlertType | null;
  dialog?: DialogType | null;
  loading: boolean;
  loadingText?: string;
  contextMenu?: ContextMenuContentProps | null;
  setLoading: (loading: boolean, loadingText?: string) => void;
  setAudioPlayer: (audioPlayer: AudioPlayerType | null) => void;
  setNotification: (notification: NotificationType) => void;
  removeNotification: (notification: NotificationType) => void;
  setTooltip: (tooltip: TooltipContentProps | null) => void;
  setContextMenu: (contextMenu: ContextMenuContentProps | null) => void;
  setDialog: (dialog: DialogType | null) => void;
  setAlert: (alert: AlertType | null) => void;
  dispose: () => void;
};

export const useFloatingUIStore = create<FloatingUIStore>((set, get) => ({
  notifications: [],
  loading: false,
  setLoading: (loading: boolean, loadingText?: string) => set({ loading, loadingText }),
  setAudioPlayer: (audioPlayer: AudioPlayerType | null) => set({ audioPlayer }),
  setNotification: (notification: NotificationType) => {
    const notifications = get().notifications || [];
    const uuid = uuidv4();
    const newNotification = { ...notification, uuid };
    set({ notifications: [...notifications, newNotification] });

    if (Number.isInteger(newNotification.closeTime)) {
      setTimeout(() => {
        get().removeNotification(newNotification);
      }, newNotification.closeTime);
    }
  },
  removeNotification: (notification: NotificationType) => {
    const notifications = get().notifications || [];
    set({ notifications: [...notifications.filter(a => a.uuid !== notification.uuid)] });
  },
  setTooltip: (tooltip: TooltipContentProps | null) => set({ tooltip }),
  setContextMenu: (contextMenu: ContextMenuContentProps | null) => set({ contextMenu }),
  setAlert: (alert: AlertType | null) => set({ alert }),
  setDialog: (dialog: DialogType | null) => set({ dialog }),
  dispose: () => set({ notifications: [], dialog: null, alert: null, tooltip: null, contextMenu: null, audioPlayer: null, loading: false }),
}));
